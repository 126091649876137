import { useBreakpoints } from '@vueuse/core'

const breakpoints = {
  sm: 640,
  md: 960,
  lg: 1024,
  xl: 1280,
  '2xl': 1536
}
export default () => useBreakpoints(breakpoints)
